<div
  *ngIf="isLoaded && submittal"
  class="maincontainer light-theme"
>
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="allDocuments"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() || !submittal.IsQAQCCompliance ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="12">
            <aecom-form-group
              columnSpan="12"
              columns="2"
              title="Processing"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item
                data-cy="completed"
                [label]="'1. Is this QA/QC compliant?'"
                [required]="true"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.IsQAQCCompliance ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setQAQCComplience(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      !submittal.IsQAQCCompliance ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setQAQCComplience(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item columnSpan="1">
              </aecom-form-item>
              <aecom-form-item
                *ngIf="submittal.IsQAQCCompliance"
                [label]="'2. Is a Bluebeam Session needed?'"
                columnSpan="1"
                data-cy="bluebeamNeeded"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.IsBluebeamRequired ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setBlueBeamSessionRequired(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      !submittal.IsBluebeamRequired ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setBlueBeamSessionRequired(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item *ngIf="submittal.IsQAQCCompliance" columnSpan="1">
              </aecom-form-item>
              <aecom-form-item
                *ngIf="showPreload && submittal.IsQAQCCompliance"
                data-cy="preload"
                [label]="'3. Preload reviewers/observers from previous revision?'"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      preload ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setPreload(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      !preload ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setPreload(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
            </aecom-form-group>
            <rejectForm style="grid-column: span 12 / auto;"
              *ngIf="!submittal.IsQAQCCompliance"
              [(submittalRejectDetail)]="submittal.submittal_reject_detail" 
              [(coverSheet)]="coverSheet" 
              [(removedFileIds)]="removedFileIds" 
              [entity]="entity" 
              [tempId]="submittal.tempId" 
              [canIssueSubmittal]="canIssueSubmittal">
            </rejectForm>
            
            <aecom-form-group
              *ngIf="
                submittal.IsBluebeamRequired &&
                submittal.submittal_bluebeam_detail &&
                submittal.IsQAQCCompliance
              "
              columnSpan="12"
              columns="2"
              title="Bluebeam Session Detail Info"
              style="margin-bottom: 70px"
            >
              <aecom-form-item [label]="'Session Name'" columnSpan="1">
                <aecom-textbox
                  data-cy="sessionName"
                  [theme]="'light'"
                  [(ngValue)]="submittal.submittal_bluebeam_detail.SessionName"
                ></aecom-textbox>
              </aecom-form-item>

              <aecom-form-item [label]="'End Date'" columnSpan="1">
                <aecom-date
                  data-cy="endDate"
                  [theme]="'light'"
                  [weekendsOff]="true"
                  [ngValue]="bluebeamDueDate"
                  [notValidBefore]="today"
                  [notEmpty]="true"
                  (change)="setDate($event)"
                  [validate]="!bluebeamDueDate && canIssueSubmittal"
                ></aecom-date>

                <div
                  class="error-message"
                  *ngIf="
                    bluebeamDueDate &&
                    bluebeamDueDate < today &&
                    canIssueSubmittal
                  "
                >
                  Dates need to be after today
                </div>
              </aecom-form-item>

              <aecom-form-item
                data-cy="endTime"
                [label]="'End Time'"
                columnSpan="1"
              >
                <input
                  type="time"
                  [value]="bluebeamDueTime"
                  (change)="setTime($event)"
                  (input)="setTime($event)"
                />

                <div
                  class="error-message"
                  *ngIf="!bluebeamDueTime && canIssueSubmittal"
                >
                  Required Field
                </div>
              </aecom-form-item>

              <aecom-form-item
                [label]="'Invitation message for Reviewers'"
                [property]="
                  submittal.submittal_bluebeam_detail.InvitationMessage?.length + '/150 characters'
                "
                columnSpan="3"
              >
                <aecom-textbox
                  data-cy="invitation"
                  theme="light"
                  [notEmpty]="true"
                  [maxlength]="150"
                  [isMultiLine]="true"
                  [(ngValue)]="submittal.submittal_bluebeam_detail.InvitationMessage"
                  [validate]="
                    canIssueSubmittal &&
                    (submittal.submittal_bluebeam_detail.InvitationMessage?.length > 150 || baseFunctionService.isInputInvalid(submittal.submittal_bluebeam_detail.InvitationMessage)) 
                  "
                ></aecom-textbox>
              </aecom-form-item>
            </aecom-form-group>

            <ng-container *ngIf="submittal.IsQAQCCompliance">
              <aecom-form-group
                columnSpan="12"
                columns="2"
                title="Assign Team"
                style="margin-bottom: 15px; position: relative"
              >
                <aecom-form-item [label]="'Coordinator'" columnSpan="1" [required]="true">
                  <aecom-dropdown
                    data-cy="coordinator"
                    [theme]="'light'"
                    placeholder="Select"
                    [data]="coordinatorsList"
                    (ngValueChange)="setCoordinator($event)"
                    [selectedId]="submittal.CoordinatorId"
                    [validate]="
                      (!submittal.CoordinatorId ||
                        submittal.CoordinatorId.trim() === '') &&
                      submittal.IsQAQCCompliance &&
                      canIssueSubmittal
                    "
                  ></aecom-dropdown>
                </aecom-form-item>
                <aecom-form-item [label]="'Project Manager'" columnSpan="1" [required]="true">
                  <aecom-dropdown
                    data-cy="manager"
                    [theme]="'light'"
                    placeholder="Select"
                    [data]="managersList"
                    (ngValueChange)="setManager($event)"
                    [selectedId]="submittal.ManagerId"
                    [validate]="
                      (!submittal.ManagerId ||
                        submittal.ManagerId.trim() === '') &&
                      submittal.IsQAQCCompliance &&
                      canIssueSubmittal
                    "
                  ></aecom-dropdown>
                </aecom-form-item>
                <aecom-form-item
                  [label]="'Internal Notes'"
                  [property]="
                    getNotesLength() + '/' + notesMaxLength + ' characters'
                  "
                  columnSpan="2"
                  class="formItemNoMargin"
                >
                  <aecom-textbox
                    data-cy="notes"
                    rows="3"
                    [theme]="'light'"
                    [notEmpty]="true"
                    [maxlength]="notesMaxLength"
                    [isMultiLine]="true"
                    [(ngValue)]="submittal.submittal_notes.Notes"
                    (ngValueChange)="setNotes($event)"
                    [validate]="canIssueSubmittal && (getNotesLength() > notesMaxLength || baseFunctionService.isInputInvalid(submittal.submittal_notes.Notes))"
                  ></aecom-textbox>
                </aecom-form-item>
              </aecom-form-group>

              <aecom-form-group
                columnSpan="12"
                columns="1"
                title="Assign Reviewer(s)"
                style="margin-bottom: 15px; position: relative"
                data-cy="assignReviewer"
              >
                <div style="width: 100%; height: 250px" class="noTableFilter">
                  <aecom-table
                    class="asignUserTable"
                    [ngValue]="reviewerTableData"
                    (ngValueChange)="reviewerTableRows = $event"
                    [theme]="'light'"
                    innerClass="list"
                  >
                    <tbody>
                      <tr
                        *ngFor="let bRow of reviewerTableRows"
                        class="assignUser_tr_hover"
                      >
                        <ng-container *ngFor="let hCol of tableHeader">
                          <td *ngIf="hCol.Type !== columnType.Hide">
                            <span class="assignUser_td_Item">
                              {{ bRow[hCol.Name] }}
                            </span>
                          </td>
                        </ng-container>
                        <td>
                          <a
                            class="btn"
                            (click)="removeUserClick(bRow, 'reviewer')"
                            ><i
                              class="
                                icon
                                icon-social_user_remove
                                icon-orange icon-lg
                              "
                            ></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </aecom-table>

                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                    "
                    *ngIf="reviewerTableData.length === 0"
                  >
                    <div class="noItem-body">
                      <div>
                        <div
                          class="
                            noItem-icon-row
                            icon
                            icon-social_user_team_big
                            icon-grey icon-xl
                          "
                        ></div>
                      </div>

                      <div class="noItem-title-row">None</div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    top: 7px;
                    right: 0;
                    cursor: pointer;
                  "
                >
                  <div
                    data-cy="addReviewer"
                    class="formGroupManu"
                    (click)="openWindowClick('reviewer')"
                  >
                    + Add REVIEWERS
                  </div>
                </div>
              </aecom-form-group>

              <aecom-form-group
                columnSpan="12"
                columns="1"
                title="Assign OBSERVER(s)"
                style="position: relative"
                data-cy="assignWatcher"
              >
                <div style="width: 100%; height: 250px" class="noTableFilter">
                  <aecom-table
                    class="asignUserTable"
                    [ngValue]="watcherTableData"
                    (ngValueChange)="watcherTableRows = $event"
                    [theme]="'light'"
                    innerClass="list"
                  >
                    <tbody>
                      <tr
                        *ngFor="let bRow of watcherTableRows"
                        class="assignUser_tr_hover"
                      >
                        <ng-container *ngFor="let hCol of tableHeader">
                          <td *ngIf="hCol.Type !== columnType.Hide">
                            <span class="assignUser_td_Item">
                              {{ bRow[hCol.Name] }}
                            </span>
                          </td>
                        </ng-container>
                        <td>
                          <a
                            class="btn"
                            (click)="removeUserClick(bRow, 'watcher')"
                            ><i
                              class="
                                icon
                                icon-social_user_remove
                                icon-orange icon-lg
                              "
                            ></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </aecom-table>

                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                    "
                    *ngIf="watcherTableData.length === 0"
                  >
                    <div class="noItem-body">
                      <div>
                        <div
                          class="
                            noItem-icon-row
                            icon
                            icon-social_user_team_big
                            icon-grey icon-xl
                          "
                        ></div>
                      </div>
                      <div class="noItem-title-row">None</div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    top: 7px;
                    right: 0;
                    cursor: pointer;
                  "
                >
                  <div
                    data-cy="addWatcher"
                    class="formGroupManu"
                    (click)="openWindowClick('watcher')"
                  >
                    + Add OBSERVERS
                  </div>
                </div>
              </aecom-form-group>
            </ng-container>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="selectUserWindowTitle"
      (close)="closeWindow($event)"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>