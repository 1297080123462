import {
  FileModel,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import RowItem from "@content/selectUser/table-row";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import ReviewerWithUserInfo from "@models/reviewerWithUserInfo";
import SubmittalCompile from "@models/submittalCompile";
import SubmittalDocumentCopy from "@models/submittalDocumentCopy";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import WatcherWithUserInfo from "@models/watcherWithUserInfo";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import {
  assignReviewersTitle,
  assignWatchersTitle,
  reviewSubmittalTypeId,
} from "@shared/staticValue";
import {
  getFilesByType,
  getVersionString,
  hasChangesFileModel,
  isFileNameInvalid,
  showDenied,
} from "@shared/utils";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import { forkJoin, Observable } from "rxjs";
import {
  EmailService,
  IPSubDocumentDownload,
  IPSubDocumentUploadReturn,
  IPSubItem,
  IPSubReviewerInit,
  IPSubWatcherInit,
  ISubAddNotificationReviewer,
  SubmittalDocumentsService,
  SubmittalReviewerService,
  SubmittalWatcherService,
} from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "app-compile",
  templateUrl: "./compile.component.html",
  styleUrls: ["./compile.component.scss"],
})
export default class CompileComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  contractId: string;

  submittal: SubmittalCompile;

  oldSubmittal: SubmittalCompile;

  entity: IPSubItem;

  reviewers: ReviewerWithUserInfo[];

  watchers: WatcherWithUserInfo[];

  attemptToIssue = false;

  noteMaxLength = 1500;
  
  internalNotesMaxLength = 250;

  currentUser: string;

  isDoc = false;

  commentResolutionForm: FileModel[] = [];

  markedUpComments: FileModel[] = [];

  prevCommentResolutionForm: FileModel[] = [];

  prevMarkedUpComments: FileModel[] = [];

  coverSheet: FileModel[] = [];
  
  commentResolutionFormNameValid = true;

  markedUpCommentsNameValid = true;

  enableSaveDraft = false;

  defaultTab = "bluebeam";

  requireBluebeam = true;

  docs: IPSubDocumentDownload[] = [];

  activeSave = false;

  selectUserWindowTitle: string;

  reviewerWindowTitle = assignReviewersTitle;

  watcherWindowTitle = assignWatchersTitle;

  allUserTableRows: RowItem[] = [];

  tableRows: RowItem[] = [];

  showSelectUser = false;

  showReview = true;

  isDBAdmin = false;

  removedFileIds: string[] = [];

  allKeyWords: string[] = [];

  showDenied = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalReviewerService: SubmittalReviewerService,
    public submittalWatcherService: SubmittalWatcherService,
    public submittalDocumentService: SubmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractUserService: LocalContractUserService,
    public emailService: EmailService,
    public baseFunctionService: BaseFunctionService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
  ) {}

  ngOnInit(): void {
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;

    this.currentUser = data.currentUserId;
    this.contractId = data.submittal.ContractId;
    this.isDBAdmin = data.isDBAdmin;
    this.isDoc = data.isDoc;
    this.allKeyWords = data.keywords;

    this.entity = data.submittal;
    this.requireBluebeam = this.entity.IsBluebeamRequired === true;

    this.showReview =
      this.entity.submittal_create[0].SubmittalTypeId ===
      reviewSubmittalTypeId;

    this.defaultTab = this.requireBluebeam ? "bluebeam" : "reviews";
    this.showDenied = showDenied(this.entity, this.localSubmittalDocumentTypeService);
    if(this.entity.submittal_notes?.length > 0 || this.entity.submittal_compile?.length > 0)
    {
      const notes = this.entity.submittal_notes.filter((n)=>{
        return n.IsDraft === false && n.Notes && n.DeletedBy === null && n.Notes.trim() !== ""
      });
      const compile = this.entity.submittal_compile.filter((c)=>{
        return c.IsDraft === false && c.DeletedBy === null
      });
      if(notes.length>0||compile.length>0)
      {
        if(notes.find((n)=>{return n.Status === SubmittalStatus.In_Review || n.Status === SubmittalStatus.Disposition || n.Status === SubmittalStatus.SPM_Review|| n.Status === SubmittalStatus.DDC_Disposition}))
        {
          this.defaultTab = "processing";
        }
      }
    }
    this.reviewers = this.entity.submittal_reviewer.map((rev) => {
      return new ReviewerWithUserInfo(rev, this.localContractUserService);
    });

    this.watchers = this.entity.submittal_watcher.map((rev) => {
      return new WatcherWithUserInfo(rev, this.localContractUserService);
    });

    this.oldSubmittal = new SubmittalCompile(this.entity.ContractId, this.entity);
    this.docs = data.documents;
    
    this.allUserTableRows = data.allUsers;

    if (
      this.oldSubmittal.submittal_compile.Guid &&
      this.docs &&
      this.docs.length > 0
    ) {
      const documnetsForThis = this.docs.filter((item) => {
        return item.ReferenceId === this.oldSubmittal.submittal_compile.Guid;
      });

      console.log("all documents", documnetsForThis);

      this.commentResolutionForm = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CommentResolutionForm,
      );

      this.markedUpComments = getFilesByType(
        documnetsForThis,
        SubmittalFileType.MarkedUpComments,
      );

      this.prevCommentResolutionForm = [...this.prevCommentResolutionForm];

      this.prevMarkedUpComments = [...this.prevMarkedUpComments];
    }

    this.submittal = _.cloneDeep(this.oldSubmittal);

    this.loadingService.stop();
  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldSubmittal);
    const current = JSON.stringify(this.submittal);
    return (
      old !== current
      || hasChangesFileModel(this.commentResolutionForm, this.prevCommentResolutionForm)
      || hasChangesFileModel(this.markedUpComments, this.prevMarkedUpComments)
    );
  }

  IsValid(): boolean {
    if(!this.submittal.IsQAQCCompliance) {
      return this.submittal.submittal_reject_detail?.Reason && this.coverSheet.length > 0;
    }
    else if (
      !this.submittal.submittal_compile.Note ||
      this.submittal.submittal_compile.Note.trim() === "" ||
      this.submittal.submittal_compile.Note.trim().length > this.noteMaxLength ||
      this.baseFunctionService.isInputInvalid(this.submittal.submittal_compile.Note) ||
      this.submittal.submittal_notes.Notes.trim().length > this.internalNotesMaxLength ||
      this.baseFunctionService.isInputInvalid(this.submittal.submittal_notes.Notes) ||
      !this.submittal.submittal_compile.Disposition ||
      this.submittal.submittal_compile.Disposition > 4 ||
      this.submittal.submittal_compile.Disposition < 1 ||
      (this.submittal.submittal_compile.Disposition === 1 ||
      this.submittal.submittal_compile.Disposition == 3
        ? this.commentResolutionForm.length === 0
        : false) ||
      !this.isFileNameValid()
    ) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;
    console.log("#", this.IsValid());
    const validation = this.IsValid();
    if (!validation) {
      return;
    }

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Confirm Action?";
      modalInstance.instance.body = this.submittal.IsQAQCCompliance 
        ? "Once submitted, your task will be completed and you will no longer be able to make updates. The submittal will be moved to the next step in the workflow."
        : "The Submittal will be sent back to the DB User. Once it's submitted, you will no longer be able to edit the submittal.";
       
      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_compile.IsDraft = false;
          this.submittal.submittal_notes.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()) return;
    if (!this.isFileNameValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Submittal will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_compile.IsDraft = true;
          this.submittal.submittal_notes.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    this.updateFiles();
    if(!this.submittal.IsQAQCCompliance) {
      this.submittal.submittal_notes.Notes = '';
    }
    this.baseFunctionService.update(this.submittal);
  }

  requestComments(): void {
    this.baseFunctionService.requestComments();
  }

  setDisposition(e: number): void {
    this.submittal.submittal_compile.Disposition = e;
    if (e === 2) {
      this.markedUpComments.forEach((item) => {
        this.deleteFile(item, SubmittalFileType.MarkedUpComments);
      });
    }
    this.enableSaveDraft = this.hasChanges();
  }

  setNote(e: string): void {
    this.submittal.submittal_compile.Note = e;
    this.enableSaveDraft = this.hasChanges();
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            getVersionString(this.submittal.SubmittalNumber),
            this.submittal.Status,
            this.submittal.Guid,
            this.submittal.submittal_compile.Guid &&
            this.submittal.submittal_compile.Guid !== ""
              ? this.submittal.submittal_compile.Guid
              : this.submittal.tempId,
          );

          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              this.enableSaveDraft = this.hasChanges();
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    this.enableSaveDraft = this.hasChanges();
  }

  addFileToAttachment(prevItem: FileModel, type: string): void {
    const item = _.cloneDeep(prevItem);
    item.Percentage = 100;
    item.Status = UploadStatus.PRELOADED;

    if (!this.isFileExist(prevItem, type)) {
      const fileUploaded = new SubmittalDocumentCopy(
        type,
        getVersionString(this.submittal.SubmittalNumber),
        this.submittal.Status,
        this.submittal.Guid,
        this.submittal.submittal_compile.Guid &&
        this.submittal.submittal_compile.Guid !== ""
          ? this.submittal.submittal_compile.Guid
          : this.submittal.tempId,
        item.Guid,
      );

      this.submittalDocumentService
        .copySubmittalDocument(fileUploaded)
        .subscribe((res) => {
          item.Guid = res.Guid;
          this.updateFileList(item, type);
          this.enableSaveDraft = this.hasChanges();
        });
    }
  }

  addFilesToAttachments(prevItems: FileModel[], type: string): void {
    const items = _.cloneDeep(prevItems);
    items.forEach((item) => {
      this.addFileToAttachment(item, type);
    });
  }

  openWindowClick(isReviewerWindow: boolean): void {
    this.activeSave = false;

    this.selectUserWindowTitle = isReviewerWindow
      ? this.reviewerWindowTitle
      : this.watcherWindowTitle;

    this.tableRows = [...this.allUserTableRows]
      .filter((user) => {
        if (this.selectUserWindowTitle == this.reviewerWindowTitle) {
          return (
            this.reviewers.findIndex((u) => {
              return u.ReviewerId === user.id;
            }) === -1
          );
        } else {
          return (
            this.watchers.findIndex((u) => {
              return u.WatcherId === user.id;
            }) === -1
          );
        }
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        if (e.length > 0) {
          this.loadingService.start();

          if (this.selectUserWindowTitle === this.reviewerWindowTitle) {
            forkJoin(
              e.map((user) => {
                const temp = {} as IPSubReviewerInit;
                temp.SubmittalId = this.submittal.Guid;
                temp.ContractId = this.contractId;
                temp.ReviewerId = user.id;

                return this.submittalReviewerService.addReviewer(temp);
              }),
            ).subscribe(async (res) => {
              console.log("res", res);

              const returnUsers = res.map((user) => {
                const userWithInfo: any = user;

                const info = this.localContractUserService.getUserById(
                  user.ReviewerId,
                );

                if (info && info.UserInfo) {
                  userWithInfo.UserInfo = info.UserInfo;
                  userWithInfo.UserId = user.ReviewerId;
                }
                return userWithInfo;
              });

              const data: ISubAddNotificationReviewer = {
                submittalId: this.submittal.Guid,
                reviewerIds: res.map((user)=>{return user.ReviewerId}),
                role: SubmittalRole.Reviewer,
              };
              await this.emailService.addSubmittalReviewerAndWatcher(data).subscribe((r) => {
                console.log(r);
              });

              this.reviewers = this.reviewers.concat(returnUsers);
              this.showSelectUser = false;
              this.enableSaveDraft = this.hasChanges();
              this.loadingService.stop();
            });
          } else {
            forkJoin(
              e.map((user) => {
                const temp = {} as IPSubWatcherInit;
                temp.SubmittalId = this.submittal.Guid;
                temp.ContractId = this.contractId;
                temp.WatcherId = user.id;
                return this.submittalWatcherService.addWatcher(temp);
              }),
            ).subscribe(async (res) => {
              console.log("res", res);
              const returnUsers = res.map((user) => {
                const userWithInfo: any = user;
                const info = this.localContractUserService.getUserById(
                  user.WatcherId,
                );

                if (info && info.UserInfo) {
                  userWithInfo.UserInfo = info.UserInfo;
                  userWithInfo.UserId = user.WatcherId;
                }

                return userWithInfo;
              });
              const data: ISubAddNotificationReviewer = {
                submittalId: this.submittal.Guid,
                reviewerIds: res.map((user)=>{return user.WatcherId}),
                role: SubmittalRole.Watcher,
              };
              await this.emailService.addSubmittalReviewerAndWatcher(data).subscribe((r) => {
                console.log(r);
              });
              this.watchers = this.watchers.concat(returnUsers);
              this.showSelectUser = false;
              this.enableSaveDraft = this.hasChanges();
              this.loadingService.stop();
            });
          }
        }
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  getNoteLength(): number {
    return this.submittal.submittal_compile?.Note
      ? this.submittal.submittal_compile?.Note.length
      : 0;
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case SubmittalFileType.CommentResolutionForm:
        index = this.commentResolutionForm.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.commentResolutionForm.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.commentResolutionForm.push(item);
            this.commentResolutionForm = this.commentResolutionForm.slice();
          }
        }
        break;
      case SubmittalFileType.MarkedUpComments:
        index = this.markedUpComments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.markedUpComments.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.markedUpComments.push(item);
            this.markedUpComments = this.markedUpComments.slice();
          }
        }
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.commentResolutionFormNameValid = true;
    this.commentResolutionForm.forEach((f) => {
      if (f && f.Name) {
        if (isFileNameInvalid(f.Name)) {
          this.commentResolutionFormNameValid = false;
        }
      }
    });
    this.markedUpCommentsNameValid = true;
    this.markedUpComments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.markedUpCommentsNameValid = false;
      }
    });
    return (
      this.commentResolutionFormNameValid &&
      this.markedUpCommentsNameValid
    );
  }

  isFileExist(item: FileModel, type: string): boolean {
    let index = -1;
    switch (type) {
      case SubmittalFileType.CommentResolutionForm:
        index = this.commentResolutionForm.findIndex((file) => {
          return file.Name === item.Name;
        });
        break;
      case SubmittalFileType.MarkedUpComments:
        index = this.markedUpComments.findIndex((file) => {
          return file.Name === item.Name;
        });
        break;
    }
    return index > -1;
  }

  hasFilesUploading(): boolean {
    return (
      this.commentResolutionForm.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      ) ||
      this.markedUpComments.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      ) || 
      this.coverSheet.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      )
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }

  updateFiles(): void {
    const allCurrentFiles = [
      ...this.commentResolutionForm,
      ...this.markedUpComments,
      ...this.coverSheet
    ];
    const allOldFiles = [
      ...this.prevCommentResolutionForm.map((f) => f.Guid),
      ...this.prevMarkedUpComments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  qaqcFail(): void {
    this.submittal.IsQAQCCompliance = false;
    this.submittal.tempId = uuidv4();
  }

  getInternalNotesLength(): number {
    return this.submittal.submittal_notes?.Notes ? this.submittal.submittal_notes.Notes.length : 0;
  }
  setInternalNotes(e: string): void {
    this.submittal.submittal_notes.Notes = e;
    this.enableSaveDraft = this.hasChanges();
  }
  reassign(): void {
    this.baseFunctionService.reassign();
  }
}
