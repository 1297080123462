<div
  class="maincontainer"
  (dragover)="onSubmittalScheduleDragOver($event)"
  (drop)="onSubmittalScheduleDrop($event)"
>
  <section class="contentcontainer">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <div class="header-title">
            <h1 class="title">SUBMITTAL LIST</h1>
            <span>{{ subtitle }}</span>
            <ng-toggle
              style="margin-left: 10px;"
              [value]="nestedView"
              [labels]="{
                'unchecked': 'Regular',
                'checked': 'Nested'
              }"
              [color]="{
                'unchecked': '#AAAAAA',
                'checked': '#00B5E2'
              }"
              [switchColor]="{
                'checked': 'white',
                'unchecked': 'white'
              }"
              [values]="{checked: true, unchecked: false}"
              width="70"
              fontSize="10"
              (change)="refreshPage($event)"
            >
            </ng-toggle>
            <!-- <aecom-toggle [ngValue]="nestedView" (ngValueChange)="refreshPage($event)" [theme]="'light'" style="color: black">Nested View</aecom-toggle> -->
          </div>
          <div
            class="header-action"
            style="margin-left: auto"
          >
            <aecom-button innerClass="btn solid lg blue" (click)="download()">
              Download Log
            </aecom-button>
          </div>

          <div
            class="header-action"
            style="margin-left: 20px"
            *ngIf="isDBAdmin || isDocControl"
          >
            <aecom-button
              data-cy="new"
              innerClass="btn solid lg blue"
              (click)="createSubmittal()"
            >
              New Submittal
            </aecom-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="{ tablestyle: fixedheadercss }">
      <div class="col-12">
        <aecom-table
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          [theme]="'light'"
          innerClass="list cus list-view-page"
          id="excel-table"
        >
          <div class="filters-section">
            <div class="d-flex">
              <div class="pr-15" style="width: 391px">
                <aecom-textbox
                  [theme]="'light'"
                  [(ngValue)]="searchText"
                  name="table-search"
                  placeholder="Search"
                  iconLeft="icon-basic_magnifier"
                  (keyupEnter)="filterData()"
                ></aecom-textbox>
              </div>
              <div class="pr-15" style="width: 300px">
                <aecom-dropdown
                  [theme]="'light'"
                  [placeholder]="showPhaseAndContractRequirement ? 'Contract Requirements' : isOld ? 'All Specifications' : 'UniFormat Level 2 Category'"
                  [data]="specificationFilter"
                  [autocomplete]="true"
                  [(selectedId)]="specificationFilterId"
                  (ngValueChange)="changeFilter($event, 'specificationFilter')"
                ></aecom-dropdown>
              </div>

              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="documentFilter"
                  [placeholder]="isOld ? 'All Document Types' : 'Document Type'"
                  [autocomplete]="true"
                  [(selectedId)]="documentFilterId"
                  (ngValueChange)="changeFilter($event, 'documentFilter')"
                ></aecom-dropdown>
              </div>

              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="submittalTypeFilter"
                  [placeholder]="isOld ? 'All Submittal Types' : 'Review Type'"
                  [(selectedId)]="submittalTypeFilterId"
                  (ngValueChange)="changeFilter($event, 'submittalTypeFilter')"
                ></aecom-dropdown>
              </div>
              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="stepDropdownFilter"
                  [placeholder]="'All Steps'"
                  [(selectedId)]="stepFilterId"
                  (ngValueChange)="changeFilter($event, 'stepDropdownFilter')"
                ></aecom-dropdown>
              </div>
              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="submittalFilter"
                  [placeholder]="isOld ? 'All Submittals' : 'Status'"
                  [(selectedId)]="submittalFilterId"
                  (ngValueChange)="changesubmittalFilter($event)"
                ></aecom-dropdown>
              </div>
            </div>

            <div class="sub-status">
              <!-- NOTE: TO IMPLEMENT LATER -->
              <!-- <span>status</span> -->
              <!-- <div>
                <ng-container *ngFor="let filterBtn of statusFilters; let i = index">
                  <aecom-button
                    [title]="filterBtn.name"
                    innerClass="btn btn-icon clear sm"
                    icon="{{ filterBtn.icon }} {{ filterBtn.active ? '' : 'disabled' }}"
                    (click)="toggleStatus(i)"
                  ></aecom-button>
                </ng-container>
              </div> -->
            </div>
          </div>

          <thead>
            <tr class="listRFI_tr">
              <th *ngIf="nestedView" [ngStyle]="{'width.px':widthExpandButton}"></th>
              <th class="sort" id="priority" [ngStyle]="{'width.px':widthPriority}">Priority</th>
              <th class="sort" id="submittalNumber" [ngStyle]="{'width.px':widthId}">{{isOld ? 'Submittal' : 'ID'}}</th>
              <th class="sort" id="submittalTitle">{{isOld ? 'Submittal Title' : 'Title'}}</th>
              <th class="sort" [id]="showPhaseAndContractRequirement?'contractRequirementTitle':'specificationTitle'">{{showPhaseAndContractRequirement ? 'Contract Requirements' : isOld ? 'Specification' : 'Uniformat Level 2'}}</th>
              <th class="sort" id="doctypeCode">Document Type</th>
              <th class="sort" id="submittalTypeTitle">{{isOld ? 'Submittal Type' : 'Review Type'}}</th>
              <th class="sort" id="issueDateSort" [ngStyle]="{'width.px':widthDate}">Issue Date</th>
              <th *ngIf="!isDBAdmin" class="sort" id="internalDueDateSort" [ngStyle]="{'width.px':widthDate}">Internal Due</th>
              <th *ngIf="isDBAdmin" class="sort" id="expectedResponseDateSort" [ngStyle]="{'width.px':widthDate}">Due Date</th>
              <th class="sort" id="responseDateSort" [ngStyle]="{'width.px':widthDate}">Response Date</th>
              <th class="sort" id="ballInCourt">Ball in Court</th>
              <th class="sort" id="status">{{isOld ? 'Status' : 'Step'}}</th>
              <th class="sort no-print" id="response" [ngStyle]="{'width.px':widthAction}">{{isOld ? 'Response' : 'Action'}}</th>
              <th *ngIf="showAltUrl" class="no-print" id="altUrl" style="padding: 0" [ngStyle]="{'width.px':widthAlt}"></th>
            </tr>
          </thead>
          <tbody data-cy="tbody">
            <ng-container *ngFor="let row of tableRows">
              <ng-container 
                  [ngTemplateOutlet]="rowItem" 
                  [ngTemplateOutletContext]="{row:row}">
              </ng-container>
              <ng-container *ngIf="nestedView && !row.collapsed && !row.isHidden" >
                <ng-container *ngFor="let subrow of row.items">
                  <ng-container 
                      [ngTemplateOutlet]="rowItem" 
                      [ngTemplateOutletContext]="{row:subrow}">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </aecom-table>
      </div>
    </div>
  </section>
</div>
<ng-template #rowItem let-row="row">
  <tr *ngIf="!row.isHidden" [ngClass]="{'grayRow': !row.items}">
    <td *ngIf="nestedView" [ngStyle]="{'width.px':widthExpandButton}">
      <ng-container *ngIf="row.items && row.items.length > 0" >
        <i *ngIf="row.collapsed; else expanded" class="icon plus-square icon-lg" (click)="row.collapsed=!row.collapsed"></i>
        <ng-template #expanded>
          <i class="icon dash-square icon-lg" (click)="row.collapsed=!row.collapsed"></i>
        </ng-template>
      </ng-container>
      <!-- <aecom-checkbox *ngIf="row.items && row.items.length > 0" [(ngValue)]="row.collapsed"></aecom-checkbox> -->
    </td>
    <td [ngStyle]="{'width.px':widthPriority}">
      <div class="no-hover" *ngIf="row.items; else childRow">
        <aecom-button 
          *ngIf="row.amended" 
          innerClass="btn solid sm blue unchange mb4 w100"
        > 
          Amended 
        </aecom-button>
        <aecom-button
          *ngIf="row.isConfidential"
          innerClass=" btn solid sm red unchange mb4 w100"
        >
          Confidential
        </aecom-button>

        <aecom-button
          *ngIf="row.priority === 1"
          innerClass="  btn solid sm grey unchange w100"
        >
          Low
        </aecom-button>

        <aecom-button
          *ngIf="row.priority === 2"
          innerClass=" btn solid sm orange unchange w100"
        >
          Medium
        </aecom-button>

        <aecom-button
          *ngIf="row.priority === 3"
          innerClass=" btn solid sm red unchange w100"
        >
          High
        </aecom-button>
      </div>
      <ng-template #childRow>
        <div>
          <i class="icon arrow-return-right icon-lg"></i>
        </div>
      </ng-template>
    </td>

    <td [ngStyle]="{'width.px':widthId}">
      {{ row.submittalNumber }}
    </td>

    <td>
      {{ row.submittalTitle }}
    </td>

    <td>
      {{ showPhaseAndContractRequirement ? row.contractRequirementTitle : row.specificationTitle }}
    </td>

    <td>{{ row.doctypeCode }}_{{ row.doctypetitle }}</td>

    <td>
      {{ row.submittalTypeTitle }}
    </td>

    <td [ngStyle]="{'width.px':widthDate}">
      {{ row.issueDate }}
    </td>

    <td *ngIf="!isDBAdmin" [ngStyle]="{'width.px':widthDate}">
      {{ row.internalDueDate }}
    </td>

    <td *ngIf="isDBAdmin" [ngStyle]="{'width.px':widthDate}">
      {{ row.expectedResponseDate }}
    </td>

    <td [ngStyle]="{'width.px':widthDate}">
      {{ row.responseDate }}
    </td>

    <td>
      <ng-container *ngIf="row.ballInCourtUser">
        <span>{{ row.ballInCourtUser.displayName }}</span>
        <br *ngIf="isOld" />
        <span *ngIf="isOld">{{ row.ballInCourtUser.mail }}</span>
      </ng-container>

      <ng-container *ngIf="!row.ballInCourtUser">
        <span>{{ row.ballInCourtRole }}</span
        ><br />
      </ng-container>
    </td>

    <td>
      <div>
        <i [class]="row.statusicon"></i>

        <span data-cy="status">{{ row.statusText }}</span>

        <br />

        <span
          *ngIf="row.substatusText && row.substatusText !== null"
          style="color: #0083db; font-size: 16px"
        >
          {{ row.substatusText }}
        </span>
        <div
          *ngIf="isDBAdmin && row.showRecallRejected"
          style="color: #9b247f"
        >
          Recall Rejected
        </div>
      </div>

      <div
        style="padding-bottom: 5px"
        *ngIf="
          !isDBAdmin &&
          (row.status === 'Compiling') && row.compilingValue != -1
        "
      >
        <mat-progress-bar
          class="bbj-progress-bar"
          mode="determinate"
          [value]="row.compilingValue"
        ></mat-progress-bar>
      </div>
    </td>

    <td class="no-print" [ngStyle]="{'width.px':widthAction}">
      <!-- "getBtnUrl(row.status,row.id,contractId, row.viewOnly)"-->
      <aecom-button
        data-cy="btn"
        [innerClass]="row.btnClass"
        (click)="goTo(row.btnUrl)"
      >
        {{ row.btnText }}
      </aecom-button>
    </td>

    <td *ngIf="showAltUrl" class="no-print" style="padding: 0" [ngStyle]="{'width.px':widthAlt}">
      <i
        *ngIf="row.altUrl"
        class="icon-action_more_option_circle icon-blue"
        style="
          cursor: pointer;
          display: block;
          height: 30px;
        "
        (click)="goTo(row.altUrl)"
      ></i>
    </td>
  </tr>
</ng-template>
