import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import tableHeader from "@content/selectUser/table-header";
import TableHeaderCol from "@content/selectUser/table-header-col";
import RowItem from "@content/selectUser/table-row";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import ColumnType from "@models/columnType";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalQaqc from "@models/submittalQaqc";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import BaseFunctionService from "@services/baseFunction.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import { assignReviewersTitle, assignWatchersTitle } from "@shared/staticValue";
import {
  getDate,
  getDisplayedTime,
  getFilesByType,
  getFormattedTime,
  getTime,
  getVersionString,
  hasChangesFileModel,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  IPSubItem,
} from "src/app/api-generated";
import { IPSubDocumentDownload } from "src/app/api-generated/model/iPSubDocumentDownload";

@Component({
  selector: "app-submitted",
  templateUrl: "./submitted.component.html",
  styleUrls: ["./submitted.component.scss"],
})
export default class SubmittedComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  allUserTableRows: RowItem[] = [];
  contractId: string;
  currentUser: string;

  submittal: SubmittalQaqc;
  oldSubmittal: SubmittalQaqc;

  entity: IPSubItem;

  managersList: ISelectItem[] = [];
  coordinatorsList: ISelectItem[] = [];
  
  reviewerWindowTitle = assignReviewersTitle;
  reviewerTableData: RowItem[] = [];
  reviewerTableRows: RowItem[] = [];
  
  watcherWindowTitle = assignWatchersTitle;
  watcherTableData: RowItem[] = [];
  watcherTableRows: RowItem[] = [];
  
  selectUserWindowTitle: string;
  showSelectUser = false;

  enableSaveDraft = false;
  saved = false;
  canIssueSubmittal = false;
  tableRows: RowItem[] = [];

  bluebeamDueDate: Date | null;
  bluebeamDueTime: string;

  tableHeader: TableHeaderCol[] = tableHeader;
  activeSave = false;
  columnType = ColumnType;

  coverSheet: FileModel[];
  oldCoverSheet: FileModel[];
  coverSheetNameValid = true;

  isLoaded = false;
  today: Date;

  allDocuments: IPSubDocumentDownload[];
  
  removedFileIds: string[] = [];

  preload = false;
  showPreload = false;
  private previousReviewers: string[] = [];
  private previousWatchers: string[] = [];
  notesMaxLength = 250;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public localContractuServices: LocalContractService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.contractId = this.localContractuServices.currentContract.Guid;
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;
    this.currentUser = data.currentUserId;
    this.entity = data.submittal;
    this.today = new Date();
    this.today.setDate(new Date().getDate() + 1);

    this.oldSubmittal = new SubmittalQaqc(this.contractId, data.submittal);
    if(getVersionString(this.entity.SubmittalNumber)!=="REV00")
    {
      this.showPreload = true;
      this.previousReviewers = this.entity.previous_reviewer.map((u)=>u.ReviewerId);
      this.previousWatchers = this.entity.previous_watcher.map((u)=>u.WatcherId);
    }
    this.allUserTableRows = data.allUsers;
    this.managersList = data.managersList;
    this.coordinatorsList = data.coordinatorsList;
    
    if (!this.oldSubmittal.submittal_bluebeam_detail.Guid) {
      this.bluebeamDueTime = null;
    } else {
      this.bluebeamDueDate = new Date(this.oldSubmittal.submittal_bluebeam_detail.Due);
      this.bluebeamDueTime = getFormattedTime(this.oldSubmittal.submittal_bluebeam_detail.Due);
    }

    this.reviewerTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubmittal.submittal_reviewer.includes(u.id);
    });

    this.watcherTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubmittal.submittal_watcher.includes(u.id);
    });

    this.allDocuments = data.documents;

    const documentForThis = data.documents.filter((item) => {
      return (
        item.ReferenceId === this.oldSubmittal.submittal_reject_detail.Guid
      );
    });

    this.oldCoverSheet = getFilesByType(documentForThis, SubmittalFileType.CoverSheetStampSigned);

    this.coverSheet = _.cloneDeep(this.oldCoverSheet);

    this.submittal = _.cloneDeep(this.oldSubmittal);
    this.isLoaded = true;
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const submittal = JSON.stringify(this.submittal);
    const oldSubmittal = JSON.stringify(this.oldSubmittal);

    return (
      oldSubmittal != submittal || hasChangesFileModel(this.coverSheet, this.oldCoverSheet)
    );
  }

  IsValid(): boolean {
    if (this.submittal.IsQAQCCompliance) {
      if (
        this.submittal.IsBluebeamRequired === true &&
          (this.submittal.submittal_bluebeam_detail.InvitationMessage.length > 150 ||
            this.baseFunctionService.isInputInvalid(this.submittal.submittal_bluebeam_detail.InvitationMessage) ||
            _.isNil(this.bluebeamDueTime) ||
            _.isNil(this.bluebeamDueDate) ||
        (this.bluebeamDueDate && this.bluebeamDueDate < this.today))
      ) {
        return false;
      }

      return (
        !(
          _.isEmpty(this.submittal.CoordinatorId) ||
          _.isEmpty(this.submittal.ManagerId)
        ) && this.submittal.submittal_notes.Notes.trim().length <= this.notesMaxLength && !this.baseFunctionService.isInputInvalid(this.submittal.submittal_notes.Notes)
      );
    } else {
      return (this.submittal.submittal_reject_detail?.Reason &&  !this.baseFunctionService.isInputInvalid(this.submittal.submittal_reject_detail.Reason)) && this.coverSheet.length > 0;
    }
  }

  submit(): void {
    this.canIssueSubmittal = true;

    const validation = this.IsValid();

    if (!validation) {
      return;
    } else if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      let title: string;

      if (!this.submittal.IsQAQCCompliance) {
        title = "Reject Submittal?";
      } else {
        title = "Confirm Action?";
      }

      const body = this.submittal.IsQAQCCompliance
        ? "Once submitted, your task will be completed and you will no longer be able to make updates. The submittal will be moved to the next step in the workflow."
        : "The Submittal will be sent back to the DB User. Once it's submitted, you will no longer be able to edit the submittal.";

      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";

      modalInstance.instance.title = title;

      modalInstance.instance.body = body;

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_create.IsDraft = false;
          this.submittal.submittal_notes.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges() || !this.submittal.IsQAQCCompliance) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Submittal will save as draft.";

      modalInstance.result.then(async (result) => {
        if (result === 1) {
          this.submittal.submittal_create.IsDraft = true;
          this.submittal.submittal_notes.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update() {
    this.loadingService.start();
    this.updateFiles();
    if(!this.submittal.IsQAQCCompliance) {
      this.submittal.submittal_notes.Notes = '';
    }
    this.baseFunctionService.update(this.submittal);
  }

  updateFiles(): void {
    const allCurrentFiles = [...this.coverSheet];
    const allOldFiles = [...this.oldCoverSheet.map((f) => f.Guid)];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];

    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  setDate(e: Date | any): void {
    if (e.target?.value == "") {
      this.bluebeamDueDate = null;
    }

    if (_.isNil(e) || e.type == "change") return;

    this.bluebeamDueDate = new Date(e);

    if (_.isNil(this.bluebeamDueTime)) {
      this.submittal.submittal_bluebeam_detail.Due = this.bluebeamDueDate.toISOString();
      this.enableSaveDraft = this.hasChanges();
      return;
    }

    const [hours, minutes] = this.bluebeamDueTime
      .split(":")
      .map((e) => parseInt(e));

    this.setFullDueDate(hours, minutes);
  }

  setTime(e: any): void {
    if (e.target.value == "") {
      this.bluebeamDueTime = null;
      this.enableSaveDraft = this.hasChanges();

      return;
    }

    const [hours, minutes] = e.target?.value.split(":");
    this.setFullDueDate(hours, minutes);
  }

  setFullDueDate(hours: number, minutes: number): void {
    const temp = _.isNil(this.bluebeamDueDate)
      ? new Date()
      : new Date(this.bluebeamDueDate);

    temp.setHours(hours);
    temp.setMinutes(minutes);
    temp.setSeconds(0);

    if (this.bluebeamDueDate) {
      this.bluebeamDueDate = temp;
    } else {
      this.bluebeamDueDate = null;
    }

    this.submittal.submittal_bluebeam_detail.Due = temp.toISOString();
    this.bluebeamDueTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    this.enableSaveDraft = this.hasChanges();
  }

  setCoordinator(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.CoordinatorId = e.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setManager(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.ManagerId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setQAQCComplience(e: boolean): void {
    this.submittal.IsQAQCCompliance = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setBlueBeamSessionRequired(e: boolean): void {
    this.submittal.IsBluebeamRequired = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setPreload(e: boolean): void {
    this.preload = e;
    if(e === true)
    {
      this.reviewerTableData = this.allUserTableRows.filter((u) => {
        return this.previousReviewers.includes(u.id);
      });
      
      this.watcherTableData = this.allUserTableRows.filter((u) => {
        return this.previousWatchers.includes(u.id);
      });
      
    }else
    {
      this.reviewerTableData = [];
      
      this.watcherTableData = [];
    }
    this.submittal.submittal_reviewer = this.reviewerTableData.map(
      (u) => {
        return u.id;
      },
    );
    this.submittal.submittal_watcher = this.watcherTableData.map(
      (u) => {
        return u.id;
      },
    );
    this.enableSaveDraft = this.hasChanges();
  }

  removeUserClick(row: RowItem, role: string): void {
    if (role === "reviewer") {
      this.reviewerTableData = this.reviewerTableData.filter(
        (reviewer) => reviewer.id !== row.id,
      );
      this.submittal.submittal_reviewer = this.reviewerTableData.map((u) => {
        return u.id;
      });
    } else {
      this.watcherTableData = this.watcherTableData.filter(
        (watcher) => watcher.id !== row.id,
      );
      this.submittal.submittal_watcher = this.watcherTableData.map((u) => {
        return u.id;
      });
    }

    this.enableSaveDraft = this.hasChanges();
  }

  openWindowClick(role: string): void {
    this.activeSave = false;

    this.selectUserWindowTitle =
      role === "reviewer" ? this.reviewerWindowTitle : this.watcherWindowTitle;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        
        if (this.selectUserWindowTitle == this.reviewerWindowTitle) {
          return (
            this.reviewerTableRows.findIndex((u) => {
              return u.id == user.id;
            }) == -1
          );
        } else {
          return (
            this.watcherTableRows.findIndex((u) => {
              return u.id == user.id;
            }) == -1
          );
        }
      })
      .map((user) => {
        user.checked = false;

        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        if (this.selectUserWindowTitle === this.reviewerWindowTitle) {
          this.reviewerTableData = this.reviewerTableData.concat(e);
          this.submittal.submittal_reviewer = this.reviewerTableData.map(
            (u) => {
              return u.id;
            },
          );
        } else {
          this.watcherTableData = this.watcherTableData.concat(e);
          this.submittal.submittal_watcher = this.watcherTableData.map(
            (u) => {
              return u.id;
            },
          );
        }
        this.showSelectUser = false;
        this.enableSaveDraft = this.hasChanges();
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  getTime(a) {
    return getTime(a);
  }

  getDisplayedTime(a) {
    return getDisplayedTime(a);
  }

  getDate(a) {
    return getDate(a);
  }

  hasFilesUploading(): boolean {
    return this.coverSheet.some(
      (item) => item.Status === UploadStatus.UPLOADING,
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.back();
  }
  getNotesLength(): number {
    return this.submittal.submittal_notes?.Notes ? this.submittal.submittal_notes.Notes.length : 0;
  }
  setNotes(e: string): void {
    this.submittal.submittal_notes.Notes = e;
    this.enableSaveDraft = this.hasChanges();
  }
}
