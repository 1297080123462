/* eslint-disable prefer-destructuring */
import { ISelectItem, ModalContainerService } from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import AuthService from "@auth/auth.service";
import { ISubmittalListPrepare } from "@models/ISubmittalListPrepare";
import SubmittalListLog from "@models/submittalListLog";
import SubmittalListRowItem from "@models/submittalListViewModel";
import SubmittalListRowItemNested from "@models/submittalListViewModelNested";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import BaseFunctionService from "@services/baseFunction.service";
import GetStatusService from "@services/get-status.service";
import LoadingService from "@services/loading.service";
import LocalApplicationRoleService from "@services/local-application_role.service";
import LocalContractService from "@services/local-contract.service";
import LocalStorageService from "@services/local-storage.service";
import LocalUserService from "@services/local-user.service";
import ScheduleUploadService from "@services/schedule-upload.service";
import {
  FAC_Contracts,
  MasterSpecType,
  TSB_Contracts,
  UniformatSpecType,
  queensGarage,
} from "@shared/staticValue";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import * as _ from "lodash";
import {
  IPSubListItem,
  PrintPDFService,
  RFIItemService,
  IPRFISpecification,
  SubmittalDocumentType,
  SubmittalPage,
  SubmittalType,
  IPRFIContractRequirement,
  Phase,
} from "src/app/api-generated";

interface statusfilter {
  name: string;
  icon: string;
  active: boolean;
  isHidden: boolean;
}

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export default class ListComponent implements OnInit {
  @HostListener("window:beforeprint", ["$event"])
  // eslint-disable-next-line class-methods-use-this
  onBeforePrint(event): void {
    this.fixedheadercss = false;
  }

  @HostListener("window:afterprint", ["$event"])
  // eslint-disable-next-line class-methods-use-this
  onAfterePrint(event): void {
    this.fixedheadercss = true;
  }

  newsubId = "new"; // remove this later;
  subtitle = "Design Builder | Admin";
  tempStatusList: statusfilter[];
  tableRows: SubmittalListRowItemNested[] = [];
  tableData: SubmittalListRowItemNested[] = [];
  tableLog: SubmittalListRowItemNested[] = [];
  contractId: string;
  contractCode: string;
  submittals: IPSubListItem[] = [];
  specificationList: IPRFISpecification[] = [];
  documenttypeList: SubmittalDocumentType[] = [];
  contractRequirementList: IPRFIContractRequirement[] = [];
  phaseList: Phase[] = [];
  pages: SubmittalPage[] = [];
  submittalTypeList: SubmittalType[] = [];
  specificationFilter: ISelectItem[] = [];
  documentFilter: ISelectItem[] = [];
  submittalTypeFilter: ISelectItem[] = [];
  stepDropdownFilter: ISelectItem[];
  submittalFilter: ISelectItem[] = [
    {
      checked: false,
      id: "open",
      name: "Open Submittals",
    },
    {
      checked: false,
      id: "closed",
      name: "Closed Submittals",
    },
    {
      checked: true,
      id: "default",
      name: "All Submittals",
    },
  ];

  specificationFilterId: string = null;
  documentFilterId: string = null;
  submittalTypeFilterId: string = null;
  submittalFilterId: string = null;
  stepFilterId: string = null;
  statusFilters: statusfilter[] = [
    {
      name: "Draft",
      icon: "icon-lg icon-action_write",
      active: true,
      isHidden: true,
    },
  ];

  data: ISubmittalListPrepare;
  role: string;

  fixedheadercss = true;

  // User groups
  isDBAdmin = false;
  isDocControl = false;
  isManager = false;
  isCoordinator = false;
  isReviewer = false;
  isWatcher = false;
  isUser = false;

  searchText: string;

  isOld = false;

  showPhaseAndContractRequirement = false;
  nestedView = true;

  widthExpandButton = 50;
  widthPriority = 100;
  widthId = 220;
  widthDate = 130;
  widthAction = 150;
  widthAlt = 50;

  showAltUrl = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public rfiItemService: RFIItemService,
    public activeModal: ModalContainerService,
    public localContractService: LocalContractService,
    public localUserService: LocalUserService,
    public printPDFService: PrintPDFService,
    public getStatusService: GetStatusService,
    private scheduleUploadService: ScheduleUploadService,
    public authService: AuthService,
    public localStorageService: LocalStorageService,
    public localApplicationRoleService: LocalApplicationRoleService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    //this.localContractService.currentContract
    const data: ISubmittalListPrepare =
      this.activatedRoute.snapshot.data.submittalList;
    this.data = data;
    this.contractId = this.localContractService.currentContract.Guid;
    this.contractCode = this.localContractService.currentContract.Code;
    this.showPhaseAndContractRequirement = this.contractCode.includes(FAC_Contracts) || this.contractCode.includes(TSB_Contracts);
    this.isOld = this.contractId == queensGarage;
    this.role = data.contractUserRoles.role;
    this.subtitle = data.contractUserRoles.subtitle;
    this.updateRole();
    this.specificationList = data.specificationList.filter((item)=>{return this.isOld ? item.SpecType === MasterSpecType : item.SpecType === UniformatSpecType});
    this.documenttypeList = data.documentTypeList;
    this.submittalTypeList = data.submittalTypeList;
    this.contractRequirementList = data.contractRequirementList;
    this.phaseList = data.phaseList;
    this.pages = data.pageList;
    this.submittals = data.submittalList;
    this.specificationFilter = this.showPhaseAndContractRequirement ? this.generatedd(this.contractRequirementList, 3) : this.generatedd(this.specificationList, 0);
    this.documentFilter = this.generatedd(this.documenttypeList, 1);
    this.submittalTypeFilter = this.generatedd(this.submittalTypeList, 2);
    this.statusFilters = this.populateSattusFilter(
      data.submittalList,
      data.contractUserRoles.statusList,
    );
    console.log(this.submittals);
    this.nestedView = this.baseFunctionService.getNestedViewFlag();
    this.tableData =  this.filterTableData();
    this.tableLog =  this.filterTableData(true);
    this.stepDropdownFilter = [...new Set(this.tableData.map((item) => item.statusText))].map((step)=>{
      return {
        name: step,
        id: step,
        checked: false,
      };
    }).sort((a,b)=>a.id.localeCompare(b.id));
    this.stepDropdownFilter.unshift({
      checked: true,
      id: "default",
      name: "All Steps",
    });
    this.showAltUrl = this.tableData.find((r)=>{return r.altUrl }) !== undefined;
    
    this.fetchSpecandDocDetails();

    console.log("loaded Data", this.tableData);
  }

  populateSattusFilter(data, fullStatusList): statusfilter[] {
    const tempstatuslist: statusfilter[] = data.map((sub) => {
      return {
        name: sub.status,
        icon: this.getStatusService.getIcon(sub.status),
        active: true,
        isHidden: false,
      };
    });

    return _.intersectionBy(tempstatuslist, fullStatusList, "name");
  }

  refreshPage(nestedView: Event): void {
    this.nestedView = (nestedView.target as HTMLInputElement).checked;
    this.baseFunctionService.setNestedViewFlag(this.nestedView);
    this.tableData = this.filterTableData();
  }

  filterTableData(forLog: boolean = false): SubmittalListRowItemNested[] {
    const largest = new Date('12/31/2100').getTime();
    //add conditions to filer data
    const listdata: SubmittalListRowItemNested[] = [];
    let key: string = "";
    this.submittals
      .sort((a,b)=>b.SubmittalNumber.localeCompare(a.SubmittalNumber))
      .forEach((sub: IPSubListItem) => {
        const [subKey,] = sub.SubmittalNumber.split('REV');
        const docType =
          sub.submittal_create && sub.submittal_create.length > 0
            ? this.documenttypeList.find((doc) => {
                return doc.Guid == sub?.submittal_create[0]?.DocumentTypeId;
              })
            : undefined;
        const spec =
          sub.submittal_create && sub.submittal_create.length > 0
            ? this.specificationList.find(
                (spec) => spec.Guid == sub?.submittal_create[0]?.SpecificationId,
              )
            : undefined;
        const subtype =
          sub.submittal_create && sub.submittal_create.length > 0
            ? this.submittalTypeList.find(
                (spec) => spec.Guid == sub?.submittal_create[0]?.SubmittalTypeId,
              )
            : undefined;
        const contractRequirement =
        sub.submittal_create && sub.submittal_create.length > 0
          ? this.contractRequirementList.find(
              (spec) => spec.Guid == sub?.submittal_create[0]?.ContractRequirementId,
            )
          : undefined;
        if(!forLog && this.nestedView)
        {
          if(key === subKey)
          {
            listdata[listdata.length -1].items.push(new SubmittalListRowItem(
              this.localApplicationRoleService,
              this.localContractService.currentContract.Guid,
              this.authService.user.Id,
              sub,
              this.role,
              this.isOld,
              docType,
              spec,
              subtype,
              contractRequirement,
              this.localUserService,
            ));
          } else {
            listdata.push(new SubmittalListRowItemNested(
              this.localApplicationRoleService,
              this.localContractService.currentContract.Guid,
              this.authService.user.Id,
              sub,
              this.role,
              this.isOld,
              docType,
              spec,
              subtype,
              contractRequirement,
              this.localUserService,
            ));
            key = subKey;
          }
        }
        else {
          listdata.push(new SubmittalListRowItemNested(
            this.localApplicationRoleService,
            this.localContractService.currentContract.Guid,
            this.authService.user.Id,
            sub,
            this.role,
            this.isOld,
            docType,
            spec,
            subtype,
            contractRequirement,
            this.localUserService,
          ));
        }
      });
    console.log(listdata.length);
    return listdata
      .sort((a,b)=>(b.DateSubmitted ? new Date(b.DateSubmitted).getTime() : largest)- (a.DateSubmitted ? new Date(a.DateSubmitted).getTime() : largest))
      .sort(function (a, b) {
        return b.initSort - a.initSort;
      });
  }

  updateRole() {
    if (this.role === SubmittalRole.DBAdmin) {
      this.isDBAdmin = true;
    } else if (this.role === SubmittalRole.Doc_Control) {
      this.isDocControl = true;
    } else if (this.role === SubmittalRole.Manager) {
      this.isManager = true;
    } else if (this.role === SubmittalRole.Coordinator) {
      this.isCoordinator = true;
    } else if (this.role.includes(SubmittalRole.Reviewer)) {
      this.isReviewer = true;
    } else if (this.role.includes(SubmittalRole.Watcher)) {
      this.isWatcher = true;
    } else {
      this.isUser = true;
    }
  }

  allowCreate(): boolean {
    return true;
  }

  createSubmittal() {
    console.log(`${this.contractId}/1/create/${this.newsubId}`);
    if(this.contractId==queensGarage)
    {
      this.router.navigateByUrl(`${this.contractId}/1/create/${this.newsubId}`);
    } else
    {
      this.router.navigateByUrl(`${this.contractId}/1/createNew/${this.newsubId}`);
    }
  }

  changeFilter(e, filterName) {
    const arrFilter = ["specificationFilter","documentFilter","submittalTypeFilter","stepDropdownFilter"];
    const fields = ["specificationId","docTypeId","submittalTypeId","statusText"];
    const index = arrFilter.findIndex((a)=>{return a === filterName});
    const columnName = index === 0 && this.showPhaseAndContractRequirement ? "contractRequirementId" : fields[index];
    if (!Array.isArray(e)) {
      if (e.id && e.id != null && e.id != "default") {
        this.updateTableWithFilter(e.id, columnName);
      } else {
        this.tableData.forEach((i) => {
          i.isHidden = false;
        });
      }
      
    } else {
      if(e.length === 0)
      {
        this.tableData.forEach((i) => {
          i.isHidden = false;
        });
      } else {
        this.updateTableWithFilter(e.map((item)=>item.id).join(', '), columnName);
      }
    }
    this.resetfilters(filterName);
  }

  updateTableWithFilter(value: string | string[], field: string) {
    this.tableData.forEach((item) => {
      if (value.includes(item[field])) {
        item.isHidden = false;
      } else {
        item.isHidden = true;
      }
    });
  }

  changesubmittalFilter(e) {
    if (!Array.isArray(e)) {
      switch (e.id) {
        case "open":
          this.tableData.forEach((i) => {
            i.isHidden = this.isFinalStep(i);
          });
          break;
        case "closed":
          this.tableData.forEach((i) => {
            i.isHidden = !this.isFinalStep(i);
          });
          break;
        default:
          this.tableData.forEach((i) => {
            i.isHidden = false;
          });
          break;
      }

      this.resetfilters("submittalFilter");
      // this.updateTable();
    }
  }

  filterData() {
    const searchVar = this.searchText.trim().toLowerCase().split(' ');
    this.tableData.forEach((i) => {
      let showNestedItem = false;
      if(i.items && i.items.length > 0)
      {
        showNestedItem = i.items.find((item)=>{return this.hasSearchValue(item, searchVar)}) !== undefined;
      }
      i.isHidden = !this.hasSearchValue(i, searchVar) && !showNestedItem;
      if(showNestedItem)
      {
        i.collapsed = false;
      }
    });
    this.resetfilters("");
    // this.updateTable();
  }

  hasSearchValue(i: SubmittalListRowItem, value: string[]): boolean {
    const searchableValue = `${i.submittalNumber},${i.submittalTitle},${this.showPhaseAndContractRequirement ? i.contractRequirementTitle : i.specificationTitle},${i.doctypeCode}_${i.doctypetitle},${i.submittalTypeTitle},${i.statusText??''}`.toLowerCase();
    return value.every((term)=>{return searchableValue.includes(term)});
  }

  generatedd(data, ddtype): ISelectItem[] {
    function sortByName (a: ISelectItem, b: ISelectItem): number {
      if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    }
    let filterlist: ISelectItem[] = [];

    if (data && data.length > 0) {
      filterlist = data.map((item) => {
        return {
          checked: false,
          id: item.Guid,
          name: ddtype === 3 ? item.Name :
            !(ddtype == 0 ? item.SpecId : item.Code)
              ? item?.Title.length > 40
                ? item?.Title.substring(0, 40) + ".."
                : item?.Title
              : item?.Title.length > 40
              ? (ddtype == 0 ? item.SpecId : item.Code) +
                "_" +
                item?.Title.substring(0, 40) +
                ".."
              : (ddtype == 0 ? item.SpecId : item.Code) + "_" + item?.Title,
        };
      });
      filterlist.sort(sortByName);
      switch(ddtype)
      {
        case 0:
          filterlist.unshift({
            checked: true,
            id: "default",
            name: "All Specifications",
          });
          break;
        case 1:
          filterlist.unshift({
            checked: true,
            id: "default",
            name: "All Document Types",
          });
          break;
        case 2:
          filterlist.unshift({
            checked: true,
            id: "default",
            name: "All Submittal Types",
          });
          break;
        case 3:
          filterlist.unshift({
            checked: true,
            id: "default",
            name: "All Contract Requirements",
          });
          break;
        default:
          break;
      }
      return filterlist;
    } else {
      return filterlist;
    }
  }

  resetfilters(selectedfilter: string) {
    const arrFilter = ["specificationFilter","documentFilter","submittalTypeFilter","stepDropdownFilter","submittalFilter"];
    arrFilter.forEach((filter, i)=>{
      if(filter !== selectedfilter)
      {
        this[`${filter}Id`] = null;
        // this[filter] = this[filter].map((item) => {
        //   if (item.id == "default") {
        //     item.checked = true;
        //   } else {
        //     item.checked = false;
        //   }
        //   return item;
        // });
      }
    });
    if(selectedfilter !== "")
    {
      this.searchText = "";
    }
  }

  // toggleStatus(index: number): void {
  //   this.statusFilters[index].active = !this.statusFilters[index].active;

  //   this.tableData.forEach((row) => {
  //     if (row.status == this.statusFilters[index].name) {
  //       row.isHidden = !this.statusFilters[index].active;
  //     }
  //   });
  // }

  // updateTable() {
  //   this.tableData.forEach((item) => {
  //     this.statusFilters.forEach((filterItem) => {
  //       if (
  //         item.status.toLowerCase() == filterItem.name.toLowerCase() &&
  //         !filterItem.active
  //       ) {
  //         item.isHidden = true;
  //       }
  //     });
  //   });
  // }

  fetchSpecandDocDetails() {
    this.tableData.forEach((item) => {
      const doc = this.documenttypeList.find((x) => x.Guid === item.docTypeId);

      item.doctypetitle = doc?.Title;
      item.doctypeCode = doc?.Code;

      const spec = this.specificationList.find(
        (x) => x.Guid === item.specificationId,
      );

      item.specificationTitle = spec ? `${spec.SpecId} - ${spec.Title}` : "";
    });

    this.loadingService.stop();
  }

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  // eslint-disable-next-line class-methods-use-this
  onSubmittalScheduleDragOver(evt: DragEvent) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  // eslint-disable-next-line class-methods-use-this
  onSubmittalScheduleDrop(evt: DragEvent): Promise<void> {
    evt.preventDefault();

    if (!this.allowCreate())
      throw new Error("No permission found to create new submitals.");

    const input: DataTransfer = evt.dataTransfer;

    if (input.files.length !== 1)
      throw new Error("Cannot use zero or multiple files");

    this.scheduleUploadService.upload(
      input.files[0],
      this.contractId,
      this.specificationList,
      this.documenttypeList,
    );

    return Promise.resolve();
  }

  isFinalStep(item: SubmittalListRowItem): boolean {
    switch (item.status) {
      case SubmittalStatus.Canceled:
      case SubmittalStatus.Voided:
      case SubmittalStatus.Resubmitted:
      case SubmittalStatus.Denied:
        return true;
      case SubmittalStatus.Accepted:
      case SubmittalStatus.Accepted_With_Comments:
      case SubmittalStatus.Reviewed:
      case SubmittalStatus.Reviewed_With_Comments:
        if (item.ballInCourt === SubmittalRole.DBAdmin) {
          return item.viewOnly;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  // download(): void {
  //   const fileName= 'ExcelSheet.xlsx';
  //   const element = document.getElementById('excel-table');
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   /* save to file */
  //   XLSX.writeFile(wb, fileName);
  // }

  download(): void {
    const users = this.localUserService.getItems();
    const data = this.tableLog.map((row) => {
      return new SubmittalListLog(
        row,
        this.submittals,
        this.specificationList,
        this.pages,
        this.contractRequirementList,
        this.phaseList,
        users,
      );
    });
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions;
    const now = new Date()
      .toLocaleDateString(undefined, options)
      .replace(", ", "-")
      .replace(" ", "-");
    const fileName = `BBJ-${this.contractCode ?? ""}-Submittal-${now}.xlsx`;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Logs");
    worksheet.addTable({
      name: "MyTable",
      ref: "A1",
      headerRow: true,
      style: {
        theme: "TableStyleMedium4",
        showRowStripes: true,
      },
      columns: this.isDBAdmin
        ? [
            { name: "Priority", filterButton: true },
            { name: "Submittal #", filterButton: true },
            { name: "Submittal Title", filterButton: true },
            { name: "CSI Division", filterButton: true },
            { name: "Specification", filterButton: true },
            { name: "Document Type", filterButton: true },
            { name: "Submittal Type", filterButton: true },
            { name: "Drawing/Pg count", filterButton: true },
            { name: "Contract Requirement", filterButton: true },
            { name: "Phase", filterButton: true },
            { name: "Issue Date", filterButton: true },
            { name: "Due Date", filterButton: true },
            { name: "Response Date", filterButton: true },
            { name: "BIC (Name & Email)", filterButton: true },
            { name: "Status", filterButton: true },
            { name: "Reference No.", filterButton: true },
          ]
        : [
            { name: "Priority", filterButton: true },
            { name: "Submittal #", filterButton: true },
            { name: "Submittal Title", filterButton: true },
            { name: "CSI Division", filterButton: true },
            { name: "Specification", filterButton: true },
            { name: "Document Type", filterButton: true },
            { name: "Submittal Type", filterButton: true },
            { name: "Drawing/Pg count", filterButton: true },
            { name: "Contract Requirement", filterButton: true },
            { name: "Phase", filterButton: true },
            { name: "Issue Date", filterButton: true },
            { name: "Internal Due", filterButton: true },
            { name: "Due Date", filterButton: true },
            { name: "Response Date", filterButton: true },
            { name: "BIC (Name & Email)", filterButton: true },
            { name: "Project Manager", filterButton: true },
            { name: "Project Manager (Previous)", filterButton: true },
            { name: "Coordinator", filterButton: true },
            { name: "DDC Manager", filterButton: true },
            { name: "DDC Manager (Previous)", filterButton: true },
            { name: "Senior Manager", filterButton: true },
            { name: "Status", filterButton: true },
            { name: "Final Disposition", filterButton: true },
            { name: "Reference No.", filterButton: true },
            { name: "Reviewers", filterButton: true },
            { name: "Observers", filterButton: true },
          ],
      rows: data.map((d) => {
        return this.isDBAdmin
          ? [
              d.priority,
              d.submittalNumber,
              d.submittalTitle,
              d.division,
              d.specification,
              d.docType,
              d.submittalType,
              d.page,
              d.contractRequirement,
              d.phase,
              d.issueDate,
              d.dueDate,
              d.responseDate,
              d.bic,
              d.status,
              d.reference,
            ]
          : [
              d.priority,
              d.submittalNumber,
              d.submittalTitle,
              d.division,
              d.specification,
              d.docType,
              d.submittalType,
              d.page,
              d.contractRequirement,
              d.phase,
              d.issueDate,
              d.internalDue,
              d.dueDate,
              d.responseDate,
              d.bic,
              d.manager,
              d.altManager,
              d.coordinator,
              d.ddcManager,
              d.altDDCManager,
              d.srManager,
              d.status,
              d.disposition,
              d.reference,
              d.reviewers.join("\r\n"),
              d.watchers.join("\r\n"),
            ];
      }),
    });
    if (this.isDBAdmin) {
      worksheet.getColumn(14).alignment = { wrapText: true };
      worksheet.getColumn(15).alignment = { wrapText: true };
    } else {
      worksheet.getColumn(15).alignment = { wrapText: true };
      worksheet.getColumn(16).alignment = { wrapText: true };
      worksheet.getColumn(17).alignment = { wrapText: true };
      worksheet.getColumn(18).alignment = { wrapText: true };
      worksheet.getColumn(19).alignment = { wrapText: true };
      worksheet.getColumn(20).alignment = { wrapText: true };
      worksheet.getColumn(21).alignment = { wrapText: true };
      worksheet.getColumn(24).alignment = { wrapText: true };
      worksheet.getColumn(25).alignment = { wrapText: true };
    }
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength > 20 ? 20 : maxLength;
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, fileName);
    });
  }
}
