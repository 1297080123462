import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import AuthService from "@auth/auth.service";
import ReviewerWithUserInfo from "@models/reviewerWithUserInfo";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import WatcherWithUserInfo from "@models/watcherWithUserInfo";
import LocalContractUserService from "@services/local-contractUser.service";
import { isReviewer, shouldShowAltManager } from "@shared/utils";
import { IPSubDocumentDownload, IPSubItem } from "src/app/api-generated";

@Component({
  selector: "leftPanel",
  templateUrl: "./leftPanel.component.html",
})
export default class LeftPanelComponent implements OnInit {
  @Input() entity: IPSubItem;
  
  @Input() docs: IPSubDocumentDownload[];

  @Input() defaultTab = "details";

  @Input() allowMovingFiles = false;

  @Input() turnOnNotifyUser = false;

  @Input() showAddUserIcon = false;

  @Input() reviewers: ReviewerWithUserInfo[] = [];

  @Input() watchers: WatcherWithUserInfo[] = [];

  @Output() moveCoverSheet = new EventEmitter<FileModel>();

  @Output() moveCoverSheets = new EventEmitter<FileModel[]>();

  @Output() moveResolutionForm = new EventEmitter<FileModel>();

  @Output() moveResolutionForms = new EventEmitter<FileModel[]>();

  @Output() moveComment = new EventEmitter<FileModel>();

  @Output() moveComments = new EventEmitter<FileModel[]>();

  @Output() addUsers = new EventEmitter<boolean>();

  

  isDBAdmin = false;

  requireBluebeam = true;

  showProcessing = false;

  showDispositionTab = false;

  showDraftDispositionTab = false;

  showReviewTab = false;

  hasReviewerRole = false;

  allowMovingFilesProcessingTab = false;

  allowMovingFilesBluebeamTab = false;

  allowMovingFilesDraftTab = false;

  constructor(public authService: AuthService, public localContractUserService: LocalContractUserService){}
  
  ngOnInit(): void {
    const role = this.localContractUserService.currentUserContractRole;
    this.requireBluebeam = this.entity.IsBluebeamRequired;
    if(this.reviewers.length === 0)
    {
      this.reviewers = this.entity.submittal_reviewer.map((rev) => {
        return new ReviewerWithUserInfo(rev, this.localContractUserService);
      });
    }
    if(this.watchers.length === 0)
    {
      this.watchers = this.entity.submittal_watcher.map((rev) => {
        return new WatcherWithUserInfo(rev, this.localContractUserService);
      });
    }
    const currentUserId = this.authService.user.Id;
    this.isDBAdmin = role === SubmittalRole.DBAdmin;
    const isDocControl = role === SubmittalRole.Doc_Control;
    const isManager = currentUserId === this.entity.ManagerId;
    const isCoordinator = currentUserId === this.entity.CoordinatorId;
    const isDDCManager = currentUserId === this.entity.DDCManagerId;
    const isSRManager = currentUserId === this.entity.SrManagerId;
    this.hasReviewerRole = isReviewer(this.entity, currentUserId);
    
    if(!this.isDBAdmin)
    {
      this.showProcessing = true;
      this.showReviewTab = (this.requireBluebeam && this.entity.submittal_bluebeam_detail !== undefined) ||
          (!this.requireBluebeam && this.reviewers && this.reviewers.length > 0 && shouldShowAltManager(this.entity));
      if(this.entity.submittal_disposition && this.entity.submittal_disposition.length > 0) 
      {
        this.showDispositionTab = this.entity.submittal_disposition.find((d)=>{return d.IsDraft === false && d.ToDDC !== true && d.ToSrManager !== true}) !== undefined && !(this.entity.Status === SubmittalStatus.DDC_Disposition && isDDCManager);
        this.showDraftDispositionTab = (isDocControl || isManager || isSRManager || isCoordinator || isDDCManager || this.hasReviewerRole) && this.entity.submittal_disposition.find((d)=>{return d.IsDraft === false && d.ToDDC === true}) !== undefined;
      }
      if(this.allowMovingFiles)
      {
        this.allowMovingFilesBluebeamTab = true;
        this.allowMovingFilesDraftTab = true;
        this.allowMovingFilesProcessingTab = true;
        const compileStatusArr: string[] = [SubmittalStatus.Review_Period, SubmittalStatus.Compiling, SubmittalStatus.Bluebeam_Compiling];
        if(compileStatusArr.includes(this.entity.Status) && !isCoordinator)
        {
          this.allowMovingFilesBluebeamTab = false;
          this.allowMovingFilesProcessingTab = false;
        }
      }
    }
    
  }

  coverSheetOut(item: FileModel): void {
    // console.log(item);
    this.moveCoverSheet.emit(item);
  }

  coverSheetsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveCoverSheets.emit(item);
  }

  resolutionFormOut(item: FileModel): void {
    // console.log(item);
    this.moveResolutionForm.emit(item);
  }

  resolutionFormsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveResolutionForms.emit(item);
  }

  commentOut(item: FileModel): void {
    // console.log(item);
    this.moveComment.emit(item);
  }

  commentsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveComments.emit(item);
  }

  addUsersClick(isReviewer: boolean): void {
    this.addUsers.emit(isReviewer);
  }
}
