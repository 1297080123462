import { ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { Injectable } from "@angular/core";
import { ContractUserApplicationService, IPSubItem, SubmittalReviewerService, SubmittalService } from "../api-generated";
import { ActivatedRoute, Router } from "@angular/router";
import AuthService from "@auth/auth.service";
import LoadingService from "./loading.service";
import LocalContractUserService from "./local-contractUser.service";
import RecallRequest from "@models/recallRequest";
import _ from "lodash";
import SubmittalRecall from "@models/submittalRecall";
import { HttpErrorResponse } from "@angular/common/http";
import { errorHandle } from "@shared/utils";
import { queensGarage } from "@shared/staticValue";
import SubmittalDelegate from "@models/submittalDelegate";
import SubmittalItemUpdateBase from "@models/submittalItemUpdateBase";
import SubmittalQaqc from "@models/submittalQaqc";
import SubmittalResponse from "@models/submittalResponse";
import SubmittalInReview from "@models/submittalInReview";
import SubmittalBluebeamRequestComments from "@models/submittalBluebeamRequestComments";
import SendBackModalComponent from "@shared/sendBack-modal/sendBack-modal.component";
import LocalApplicationRoleService from "./local-application_role.service";
import SubmittalDispositionRelease from "@models/submittalDispositionRelease";
import SubmittalDisposition from "@models/submittalDisposition";
import SubmittalCompile from "@models/submittalCompile";
import SubmittalRecallApproval from "@models/submittalRecallApproval";
import SubmittalCreate from "@models/submittalCreate";
import LocalSubmittalItemService from "./local-submittalItem.service";
import ContractNestedViewItem from "@models/contractNestedViewItem";
import SubmittalReopenApproval from "@models/submittalReopenApproval";
import SubmittalReopenStep from "@models/submittalReopenStep";
import { v4 as uuidv4 } from 'uuid';
import SubmittalRole from "@models/submittalRole";
import ReopenModalComponent from "@shared/reopen-modal/reopen-modal.component";
import RecallModalComponent from "@shared/recall-modal/recall-modal.component";
import DelegateModalComponent from "@shared/delegate-modal/delegate-modal.component";
import ReadRecallModalComponent from "@shared/readRecall-modal/readRecall-modal.component";
import RequestCommentsModalComponent from "@shared/requestComments-modal/requestComments-modal.component";
import SubmittalUpdateDueDateStep from "@models/submittalUpdateDueDateStep";
import { UpdateDueDateModalComponent } from "@shared/updateDueDate-modal/updateDueDate-modal.component";

@Injectable({
  providedIn: "root",
})
export default class BaseFunctionService {
  entity: IPSubItem;
  contractId: string;
  isOld = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalService: SubmittalService,
    public localContractUserService: LocalContractUserService,
    public submittalReviewerService: SubmittalReviewerService,
    public localApplicationRoleServices: LocalApplicationRoleService,
    public contractUserApplicationService: ContractUserApplicationService,
    public localSubmittalItemService: LocalSubmittalItemService,
  ) {}

  setEntity(entity: IPSubItem): void {
    this.entity = entity;
    this.contractId = this.entity.ContractId;
    this.isOld = this.contractId == queensGarage;
  }

  setContractId(contractId: string): void {
    this.contractId = contractId;
  }

  back(): void {
    this.router.navigateByUrl(`${this.contractId}/list`);
  }

  backAfterConfirm(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Cancel editing?";
    modalInstance.instance.body = "Your changes will not be saved.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      }
    });
  }

  recallSubmittal(): void {
    const modalInstance = this.activeModal.open(RecallModalComponent);

    modalInstance.result.then((result: RecallRequest) => {
      console.log("result", result);

      if (
        (result && result.comments && !(_.isNumber(result.comments) && result.comments === 0)) ||
        _.isString(result.comments)
      ) {
        this.loadingService.start();
        const request = new SubmittalRecall(this.contractId, result, this.entity);

        this.submittalService.subUpdate(request).subscribe(
          () => {
            this.loadingService.stop();
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop();
            errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  reviseSubmittal(): void {
    if(this.isOld)
    {
      this.router.navigateByUrl(`${this.contractId}/0/create/${this.entity.Guid}`);
    }
    else {
      this.router.navigateByUrl(`${this.contractId}/0/createNew/${this.entity.Guid}`);
    }
  }

  reassign(): void {
    const modalInstance = this.activeModal.open(DelegateModalComponent);
    modalInstance.instance.subItem = this.entity;

    modalInstance.result.then((result: SubmittalDelegate | null) => {
      console.log("result", result);

      if (result) {
        this.loadingService.start();
        this.submittalService.subDelegate(result).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  dbReadReject(): void {
    const modalInstance = this.activeModal.open(ReadRecallModalComponent);

    modalInstance.instance.title = "Recalled Submittal has been Rejected";
    modalInstance.instance.body =
      "Doc Control rejected your recall request. Please contact directly to the Doc Control for any question.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new SubmittalItemUpdateBase(
          this.contractId,
          this.entity,
        );

        this.submittalService.dbReadRejectedRecall(item).subscribe();
      }
    });
  }

  dbViewSubmittal(): void {
    const item = new SubmittalItemUpdateBase(this.contractId, this.entity);

    this.submittalService.viewedSubmittal(item).subscribe(
      (r) => {
        console.log(r);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
    );
  }

  dbVoidSubmittal(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Void Submittal?";
    modalInstance.instance.body = "Your Submittal will be voided.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new SubmittalItemUpdateBase(
          this.contractId,
          this.entity,
        );
        this.submittalService.voidSubmittal(item).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  showFileProgressNotification(): void {
    this.OpenAlert("Please wait until all your files have been uploaded", "File upload in progress");
  }

  dispositionFormAlert() {
    this.OpenAlert("The selected disposition was changed after generating the disposition form. Please delete the current disposition form.");
  }

  NoDispositionAlert() {
    this.OpenAlert("Please select disposition before generating the disposition form.");
  }

  OpenAlert(body: string, title = "Error") {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = title;
    modalInstance.instance.body = body;
    modalInstance.result;
  }

  requestComments(): void {
    const modalInstance = this.activeModal.open(RequestCommentsModalComponent);
    modalInstance.result.then((result) => {
      if (result && result !== 0 && result.length > 0) {
        this.loadingService.start();
        const request = new SubmittalBluebeamRequestComments(
          this.contractId,
          this.entity,
        );

        request.submittal_bluebeam_request.Comments = result;
        
        this.submittalService.subUpdate(request).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  sendBack(): void {
    const modalInstance = this.activeModal.open(SendBackModalComponent);
    modalInstance.instance.by = this.localApplicationRoleServices.getRoleById(this.entity.BallInCourt);
    modalInstance.result.then((result) => {
      if (result && result !== 0 && result.length > 0) {
        this.loadingService.start();
        const releaseDisposition = new SubmittalDispositionRelease(this.contractId, this.entity);
        releaseDisposition.submittal_notes.Notes = result;
        releaseDisposition.submittal_notes.IsDraft = false;
        this.submittalService.subUpdate(releaseDisposition).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  update(submittal: SubmittalQaqc | SubmittalItemUpdateBase | SubmittalInReview | SubmittalDisposition | SubmittalCompile | SubmittalCreate | SubmittalRecallApproval) {
    this.submittalService.subUpdate(submittal).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  submitMyReviewResponse(submittal: SubmittalResponse) {
    this.submittalReviewerService
      .submitMyReviewResponse(submittal)
      .subscribe(
        () => {
          this.loadingService.stop(true);
          this.router.navigateByUrl(`${this.contractId}/list`);
        },
        (error: HttpErrorResponse) => {
          this.loadingService.stop(true);
          errorHandle(error, this.activeModal, this.router);
        },
      );
  }

  subDelegate(submittal: SubmittalDelegate): void {
    this.submittalService.subDelegate(submittal).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  getNestedViewFlag(): boolean {
    return this.localSubmittalItemService.nestedView;
  }

  setNestedViewFlag(value: boolean): void {
    this.loadingService.start();
    const nestedViewItem = new ContractNestedViewItem(this.authService.user.Id, this.contractId, value);
    this.contractUserApplicationService.updateApplicationNestedVeiw(nestedViewItem).subscribe(
      () => {
        this.localSubmittalItemService.nestedView = value;
        this.loadingService.stop();
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop();
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  subReopen(): void {
    const role = this.localContractUserService.currentUserContractRole;
    const isDoc = role === SubmittalRole.Doc_Control;
    const item = new SubmittalReopenStep(this.contractId, this.entity);
    if(isDoc)
    {
      item.tempId = uuidv4();
    }
    const modalInstance = this.activeModal.open(ReopenModalComponent);
    modalInstance.instance.item = item;
    modalInstance.result.then((result) => {
      if (result) {
        console.log(result);
        this.loadingService.start();
        this.submittalService.subReopen(result).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  subReopenAcknowledge(): void {
    if(this.entity.submittal_reopen?.length > 0)
    {
      const reopen = this.entity.submittal_reopen.sort((a, b) =>
        new Date(b.DateRequest).getTime() - new Date(a.DateRequest).getTime(),
      )[0];
      const sub = new SubmittalReopenApproval(this.contractId, this.entity, reopen);
      this.submittalService.subReopenApproval(sub).subscribe(
        () => {
          this.loadingService.stop(true);
          this.router.navigateByUrl(`${this.contractId}/list`);
        },
        (error: HttpErrorResponse) => {
          this.loadingService.stop(true);
          errorHandle(error, this.activeModal, this.router);
        },
      );
    }
  }

  // subReopenApproval(approval: boolean, reason?: string | null): void {
  //   if(!approval)
  //   {
  //     const modalInstance = this.activeModal.open(NotificationComponent);
  //     modalInstance.instance.theme = "light";
  //     modalInstance.instance.title = "Submit Response?";
  //     modalInstance.instance.body = "<span class='warningFont'>Upon submission, DDC/AHJV reserves the right to proceed with the appropriate measures to respond and resolve issues in accordance with the Agreement.</span>";

  //     modalInstance.result.then((result) => {
  //       if (result === 1) {
  //         this.subReopenApprovalAction(approval, reason);
  //       }
  //     });
  //   }
  //   else{
  //     this.subReopenApprovalAction(approval, reason);
  //   }
  // }

  readReopened(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.showCancel = false;
    modalInstance.instance.title = "Submittal has been reopened";
    modalInstance.instance.body = "<span class='warningFont'>Please send back to Coordinator OR modify and reissue the response.</span>";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new SubmittalItemUpdateBase(
          this.contractId,
          this.entity,
        );

        this.submittalService.viewedSubmittal(item).subscribe();
      }
    });
  }

  updateDueDate(): void {
    const item = new SubmittalUpdateDueDateStep(this.contractId, this.entity);
    const modalInstance = this.activeModal.open(UpdateDueDateModalComponent);
    modalInstance.instance.dueDateItem = item;
    modalInstance.result.then((result) => {
      if (result) {
        console.log(result);
        this.loadingService.start();
        this.submittalService.subUpdateDueDate(result).subscribe({
          complete: () => {
            this.loadingService.stop(true);
            location.reload();
            //this.router.navigateByUrl(`${this.contractId}/list`);
          },
          error: (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            errorHandle(error, this.activeModal, this.router);
          },
        });
      }
    });
  }

  isInputInvalid(str: string | null | undefined): boolean {
    if(str)
    {
      const invalidChars = /[^\x20-\x7E\n\r]+/;
      return invalidChars.test(str);
    }
    return false;
  }
}

